import React , {useEffect} from "react";
import NavigationButtons from "../NavigationButtons/NavigationButtons";
import WhatsApp from "../WhatsApp/WhatsApp";

const VendeMientrasUsas = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <>
    <div className="p-6 flex flex-col justify-center items-center">
    <div className="w-4/5 sm:w-3/5 min-h-[500px] text-center text-justify">
      <h2 className="text-2xl font-extrabold mt-6 mb-6 text-center">
        Vendelo mientras lo usas:
      </h2>
      <ul className="list-disc list-inside space-y-2 text-lg font-semibold mt-6">
        <li>
          La modalidad de venta mientras utilizas tu vehículo es una opción 
          práctica que te permite comercializarlo de manera efectiva mientras aún lo usas.
        </li>
        <li>
          El proceso es sencillo: traes tu vehículo a nuestra agencia, tomamos varias fotografías
          y lo publicamos para atraer a posibles compradores. Nosotros nos encargamos de buscar al 
          comprador adecuado y coordinar una visita en la agencia para mostrar el vehículo.  
        </li>
        <li>
          Además, gestionamos todo el proceso de venta, desde la obtención de turnos 
          y documentación necesaria, hasta la transferencia del vehículo con la ayuda 
          de una gestoría profesional.
        </li>
        <li>
          Con esta modalidad, garantizamos un proceso eficiente y transparente para la venta
          de tu vehículo, permitiéndote continuar utilizándolo mientras se concreta la venta.  
        </li>
      </ul>
    </div>
  </div>
  <WhatsApp />
    <NavigationButtons/>
    </>
  );
};

export default VendeMientrasUsas;
