import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Consignacion from './components/Consignacion/Consignacion';
import VentaDirecta from './components/VentaDirecta/VentaDirecta';
import VendeMientrasUsas from './components/VendeMientrasUsas/VendeMientrasUsas';
import CeroKm from './components/CeroKm/CeroKm';
import Usados from './components/Usados/Usados';
import AutoFicha from './components/AutoFicha/AutoFicha';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import VehiculoDetalle from './components/VehiculoDetalle/VehiculoDetalle';

function App() {
  return (
    <div>
      <Router>
        <Header />  
          <Routes>
            <Route path="/consignacion" element={<Consignacion />} />
            <Route path="/compradirecta" element={<VentaDirecta />} />
            <Route path="/VendeMientrasUsas" element={<VendeMientrasUsas />} />
            <Route path="/0km" element={<CeroKm />} />
            <Route path="/usados" element={<Usados />} />
            <Route path="/auto/:auto" element={<AutoFicha />} />
            <Route path="/" element={<Main />} />
            <Route path="/:vehicleType/:vehicleName" element={<VehiculoDetalle />} />
          </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
