import React from "react";
import "./CommentCards.css";

const CommentCards = ({ profileImage, comment, userName, commentLink }) => {
  return (
    <div className="comment-card">
      <img
        src={profileImage}
        alt={`${userName}'s profile`}
        className="profile-image"
      />
      <div className="rating">
        <span className="filled-star">★</span>
        <span className="filled-star">★</span>
        <span className="filled-star">★</span>
        <span className="filled-star">★</span>
        <span className="filled-star">★</span>
      </div>
      <p className="comment">{comment}</p>
      <p className="user-name">{userName}</p>
      <a
        href={commentLink}
        target="_blank"
        rel="noopener noreferrer"
        className="comment-link"
      >
        Ver comentario
      </a>
    </div>
  );
};

export default CommentCards;
