import React, { useEffect, useState } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import CardIndividual from "../CardIndividual/CardIndividual";
import Loader from '../Loader/Loader';
import NavigationButtons from '../NavigationButtons/NavigationButtons';
import "./Usados.css";
import { useNavigate } from 'react-router-dom';
import WhatsApp from "../WhatsApp/WhatsApp";

const Usados = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storage = getStorage();
    const folderRef = ref(storage, "Autos/Usados"); 

    listAll(folderRef)
      .then((res) => {
        // Get the first image in each subfolder
        return Promise.all(
          res.prefixes.map((folder) => {
            return listAll(folder).then((res) => {
              // Filter out any JSON files
              const imageFiles = res.items.filter(
                (item) => !item.name.endsWith(".json")
              );
              if (imageFiles.length > 0) {
                return getDownloadURL(imageFiles[0]).then((url) => ({
                  url,
                  name: folder.name, // Asume que el nombre de la carpeta es el nombre del vehículo
                }));
              }  
            });
          })
        );
      })
      .then((urls) => {
        setImageUrls(urls); // set the URLs of the first image in each subfolder
        setIsLoading(false);
        window.scrollTo(0, 0);
      })
      .catch(console.error);
  }, []);

  return (
    <div>
      {isLoading ? <Loader isLoading={isLoading}/> :
      <>
        <h2 className="text-center  text-2xl font-bold mt-4 mb-4">USADOS</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          {imageUrls.map((url, index) => (
            <div className="flex items-center justify-center center-items mt-3 mb-1">
              <CardIndividual
                key={url.name}
                image={url.url}
                name={url.name}
                onInfoClick={() => navigate(`/Usados/${url.name}`)}
              />
            </div>
          ))}
        </div>
        <div className="ml-16 mr-16 mt-10 text-center">
      <h4 className="text-2xl font-extrabold mt-10 mb-6">
        Usados seleccionados
      </h4>
      <div className="mx-auto w-3/4 text-justify">
        <p className="mt-6 ml-16 mr-16 mb-16 font-semibold">
          Seleccionamos cuidadosamente nuestras unidades para ofrecer la mejor variedad de 
          vehículos usados a nuestros clientes, garantizando que comprar con nosotros sea una 
          experiencia única.
        </p>
      </div>
      </div>
      <WhatsApp />    
      <NavigationButtons/>
      </>
      }
  </div>
  );
};

export default Usados;

