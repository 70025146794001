import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import CommentCards from "./CommentCards";
import commentsData from "./comments.json";
import "./CommentsCarousel.css";

const CommentsCarousel = ({ autoSlide = true, autoSlideInterval = 7000 }) => {
  const [curr, setCurr] = useState(0);
  const comments = commentsData.slice(0, 12);

  const prev = () =>
    setCurr((curr) => (curr === 0 ? comments.length - 1 : curr - 1));
  const next = () =>
    setCurr((curr) => (curr === comments.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, [autoSlide, autoSlideInterval]);

  return (
    <div className="overflow-hidden relative w-full h-full">
      <div
        className="flex transition-transform ease-out duration-500"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {commentsData.map((comment, i) => (
          <div className="carousel-item w-full flex-shrink-0" key={i}>
            <CommentCards
              profileImage={comment.profileImage}
              comment={comment.comment}
              userName={comment.userName}
              commentLink={comment.commentLink}
            />
          </div>
        ))}
      </div>
      <div className="absolute inset-0 flex items-center justify-between p-4">
        {/* <button
          onClick={prev}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          <ChevronLeft size={40} />
        </button>
        <button
          onClick={next}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          <ChevronRight size={40} />
        </button> */}
      </div>
    </div>
  );
};

export default CommentsCarousel;
