import React from 'react';

const NavigationButtons = () => (
    <div className="flex flex-wrap justify-between mt-10 mb-12 mx-4 sm:mx-16">
      <div className="w-full sm:w-1/2 flex justify-center mb-4 sm:mb-0">
        <button
          className="w-3/4 sm:w-1/2 p-4 bg-blue-500 text-white text-lg font-semibold rounded-md cursor-pointer transition-colors duration-200 hover:bg-blue-700"
          onClick={() => window.location.href = '/'}
        >
          Volver
        </button>
      </div>
      <div className="w-full sm:w-1/2 flex justify-center">
        <button
          className="w-3/4 sm:w-1/2 p-4 bg-blue-500 text-white text-lg font-semibold rounded-md cursor-pointer transition-colors duration-200 hover:bg-blue-700"
          onClick={() => window.location.href = 'https://walink.co/f717a6'}
        >
          Contacto
        </button>
      </div>
    </div>
  );

export default NavigationButtons;