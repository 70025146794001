import React, { useEffect, useState } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import CardIndividual from "../CardIndividual/CardIndividual";
import "./CeroKm.css";
import Loader from '../Loader/Loader';
import NavigationButtons from "../NavigationButtons/NavigationButtons";
import { useNavigate } from 'react-router-dom';
import WhatsApp from "../WhatsApp/WhatsApp";

const CeroKm = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storage = getStorage();
    const folderRef = ref(storage, "Autos/nuevos"); 

    listAll(folderRef)
      .then((res) => {
        // first image in each subfolder
        return Promise.all(
          res.prefixes.map((folder) => {
            return listAll(folder).then((res) => {
              // para no agarrar el json si es el primer elemento
              const imageFiles = res.items.filter(
                (item) => !item.name.endsWith(".json")
              );
              if (imageFiles.length > 0) {
                return getDownloadURL(imageFiles[0]).then((url) => ({
                  url,
                  name: folder.name, // Asume que el nombre de la carpeta es el nombre del vehículo
                }));
              }  
            });
          })
        );
      })
      .then((urls) => {
        setImageUrls(urls);
        setIsLoading(false);
        window.scrollTo(0, 0);
      })
      .catch(console.error);
  }, []);

  return (
    <div>
      {isLoading ? <Loader isLoading={isLoading}/> :
      <>
        <h2 className="text-center  text-2xl font-bold mt-4 mb-4">Nuestros 0KM</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          {imageUrls.map((url, index) => (
            <div className="flex items-center justify-center center-items mt-3 mb-1">
              <CardIndividual
                key={url.name}
                image={url.url}
                name={url.name}
                onInfoClick={() => navigate(`/nuevos/${url.name}`)}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center">
    <div className="w-4/5 sm:w-3/5 text-center text-justify mt-10 mb-16">
      <h4 className="text-2xl font-extrabold mb-6">
        En nuestra agencia multimarca de vehículos 0km, ofrecemos ventajas distintivas 
        que hacen de tu experiencia de compra mucho más agradable:
      </h4>
      <ul className="list-disc list-inside space-y-2 text-lg font-semibold">
        <li>
          Valor justo por tu vehículo: Nos comprometemos a ofrecerte el 
          mejor precio del mercado por tu vehículo usado al momento de adquirir un 0km.
        </li>
        <li>
          Stock físico disponible: Contamos con un amplio stock físico de vehículos, 
          lo que nos permite garantizar una entrega inmediata sin largas esperas.
        </li>
        <li>
          Llave contra llave: Implementamos el concepto de llave contra llave, 
          donde tú nos entregas tu vehículo usado al momento de recibir tu vehículo 0km, 
          asegurando una transacción segura y confiable para ambas partes.
        </li>
      </ul>
    </div>
  </div>
  <WhatsApp />
<NavigationButtons/>
      </>
      }
    </div>
  );
};

export default CeroKm;

