import React, { useEffect } from 'react';
import './Loader.css';

const Loader = ({ isLoading }) => {
  useEffect(() => {
    if (isLoading) {
      window.scrollTo(0, 0);
    }
  }, [isLoading]);

  return (
    isLoading && (
      <div className='flex items-center justify-center h-screen'>
        <div className='loader'></div>
      </div>
    )
  );
};

export default Loader;