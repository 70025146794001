import React from "react";
import "./Header.css";
import logo from "../../assets/image/logo-sinFondo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  return (
    <header className="p-5 bg-white-500 text-black relative min-h-[150px]">
      <div className="absolute top-0 left-0 w-full flex justify-center">
        <a
          onClick={() => (window.location.href = "/")}
          className="block mx-auto cursor-pointer"
        >
          <img
            src={logo}
            alt="Logo Tortone"
            className="mx-auto w-[150px] md:w-[210px]"
            width={210}
            height={150}
          />
        </a>
      </div>
      <div className="absolute bottom-2.5 left-0 w-full flex justify-around items-center">
        <div className="w-1/2 flex justify-center items-center">
          <a
            href="https://maps.app.goo.gl/VMx4R53qjSQyTyhi7"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center text-sm sm:text-base md:text-xl"
          >
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              color="#EA4335"
              className="w-3 sm:w-4 md:w-5 mr-2"
            />
            35 nro° 566 e/ 6 y 7
          </a>
        </div>
        <div className="w-1/2 flex justify-center items-center">
          <a
            href="https://walink.co/f717a6"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center text-sm sm:text-base md:text-xl"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              color="#25D366"
              className="w-3 sm:w-4 md:w-5 mr-2"
            />
            221-6703602
          </a>
        </div>
      </div>
      <div className="absolute bottom-2.5 left-0 w-full">
        <div className="absolute bottom-0.3 left-0 w-full h-0.5 bg-red-500"></div>
        <div className="absolute bottom-0.2 left-0 w-full h-0.5 bg-red-500 mt-2"></div>
      </div>
    </header>
  );
};

export default Header;
