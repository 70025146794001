import React, { useEffect, useState } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import MyCarousel from "./../Carousel/Carousel";
import Card from "./../CardMain/CardMain";
import { Link } from "react-router-dom";
import imageConsignacion from "../../assets/image/mainCards/renault-stepwaybordo.jpg";
import imageVentaDirecta from "../../assets/image/mainCards/Renegade_Sport.webp";
import imageVendeMientrasUsas from "../../assets/image/mainCards/YarisBlanco.webp";
import image0km from "../../assets/image/mainCards/amarok-trendline.png";
import imageUsados from "../../assets/image/mainCards/208blanco.png";
import WhatsApp from "../WhatsApp/WhatsApp";
import CommentsCarousel from "../Comments/CommentsCarousel";
import MobileCarousel from "../MobileCarousel/MobileCarousel";
import DesktopCarousel from "../DesktopCarousel/DesktopCarousel";

const Main = () => {
  const [autoImages, setAutoImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAutoImages = async () => {
      try {
        const storage = getStorage();
        const newVehicles = await fetchImagesFromFolder(storage, "nuevo", 3);
        const usedVehicles = await fetchImagesFromFolder(storage, "Usados", 6);

        setAutoImages([...newVehicles, ...usedVehicles]);
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAutoImages();
  }, []);

  const fetchImagesFromFolder = async (storage, folder, limit) => {
    const folderRef = ref(storage, `Autos/${folder}`);
    const response = await listAll(folderRef);
    let imageUrls = [];

    for (const subfolder of response.prefixes) {
      const subfolderResponse = await listAll(subfolder);
      const imageFile = subfolderResponse.items.find(
        (item) => !item.name.endsWith(".json")
      );

      if (imageFile) {
        const url = await getDownloadURL(imageFile);
        imageUrls.push(url);
      }

      if (imageUrls.length >= limit) {
        break;
      }
    }

    return imageUrls;
  };

  return (
    <div className="container mx-auto px-4">
      {/* <div className="">
        <MobileCarousel />
        <DesktopCarousel />
      </div>
      <section className="text-center py-8">
        <h1 className="text-4xl font-bold">CONOCÉ NUESTROS SERVICIOS</h1>
        <h2 className="text-2xl">Cada propuesta pensada a tu medida</h2>
      </section> */}

      <section className="py-8">
        <h2 className="text-3xl font-bold text-center">Necesitas Vender?</h2>
        <div className="flex flex-wrap justify-center items-center mt-4">
          <div className=" w-full sm:w-1/3 p-4 flex justify-center">
            <div className="w-4/5">
              <Link to="/consignacion">
                <Card title="CONSIGNACION" image={imageConsignacion} />
              </Link>
            </div>
          </div>
          <div className=" w-full sm:w-1/3 p-4 flex justify-center ">
            <div className="w-4/5">
              <Link to="/compradirecta">
                <Card title="COMPRA DIRECTA" image={imageVentaDirecta} />
              </Link>
            </div>
          </div>
          <div className=" w-full sm:w-1/3 p-4 flex justify-center ">
            <div className="w-4/5">
              <Link to="/VendeMientrasUsas">
                <Card
                  title="VENDER MIENTRAS LO USAS"
                  image={imageVendeMientrasUsas}
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8">
        <h2 className="text-3xl font-bold text-center">Necesitas Comprar?</h2>
        <div className="flex flex-wrap justify-center items-center mt-4">
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4 flex justify-center ">
            <div className="w-4/5">
              <Link to="/0km">
                <Card title="0 KM" image={image0km} />
              </Link>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4 flex justify-center ">
            <div className="w-4/5">
              <Link to="/usados">
                <Card title="USADOS SELECCIONADOS" image={imageUsados} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8">
        <h2 className="text-3xl font-bold text-center">Nuestros Autos</h2>
        <div className="flex flex-wrap justify-center items-center mt-4 text-center">
          <div className="max-w-lx">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <MyCarousel autoSlide={true} items={autoImages} />
            )}
          </div>
        </div>
      </section>
      <section className="py-8">
        <h2 className="text-3xl font-bold text-center">Nuestras Referencias</h2>
        <div className=" w-full flex flex-wrap justify-center items-center mt-4 text-center">
          <div className="w-full max-w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl">
            <CommentsCarousel autoSlide={true} />
          </div>
        </div>
      </section>
      <WhatsApp />
    </div>
  );
};

export default Main;
