import React from "react";

function Card({ title, image }) {
  return (
    <div className="transition duration-500 ease-in-out transform hover:scale-105 cursor-pointer flex flex-col">
      <div className="h-64 overflow-hidden">
        <img src={image} alt={title} className="w-full object-contain h-full" />
      </div>
      <button className="w-full py-2 border-2 border-black bg-transparent text-black rounded-full">
        Click
      </button>
      <h2 className="text-center py-2">{title}</h2>
    </div>
  );
}

export default Card;
