import React, { useState, useEffect } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faTiktok,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/image/logo-sinFondo.png";

const Footer = () => {
  const [iconSize, setIconSize] = useState("lg");

  useEffect(() => {
    const handleResize = () => {
      setIconSize(window.innerWidth < 640 ? "lg" : "2x");
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer className=" p-4 bg-gray-200 ">
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <div className="hidden sm:flex sm:justify-center w-full sm:w-1/3 items-center text-center">
          <iframe
            title="Google Maps Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.1579742447534!2d-57.96461169999999!3d-34.9024849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a2e7ad9e0f0303%3A0xd1e7a4cd9918a4da!2sC.%2035%20566%2C%20B1902BBX%20La%20Plata%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1713816509441!5m2!1ses!2sar"
            width="410"
            height="180"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded"
          ></iframe>
        </div>
        <div className="w-full sm:w-1/3 text-center mb-4 sm:mb-0">
          <a
            onClick={() => (window.location.href = "/")}
            className="block mx-auto cursor-pointer"
          >
            <img
              src={logo}
              alt="Logo"
              className="mx-auto w-36 h-36 sm:w-48 sm:h-48"
              width={210}
            />
          </a>
        </div>
        <div className="w-full sm:w-1/3 flex flex-col items-center space-y-2">
          <div className="mb-6 text-sm sm:text-base md:text-xl">
            Seguinos en nuestras redes
          </div>
          <div className="flex justify-around w-full">
            <a
              href="https://www.facebook.com/tortoneautomotores?mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:text-blue-800 transform hover:scale-110"
            >
              <FontAwesomeIcon icon={faFacebook} size={iconSize} />
            </a>
            <a
              href="https://www.tiktok.com/@tortone.automotor?_t=8mK0pEem93e&_r=1"
              target="_blank"
              rel="noreferrer"
              className="text-black hover:text-gray-800 transform hover:scale-110"
            >
              <FontAwesomeIcon icon={faTiktok} size={iconSize} />
            </a>
            <a
              href="https://www.instagram.com/tortoneautomotoresautos?igsh=YmJucWprcm5vZWU5"
              target="_blank"
              rel="noreferrer"
              className="text-purple-600 hover:text-purple-800 transform hover:scale-110"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size={iconSize}
                className="hover:text-purple-800 transform hover:scale-110 "
                style={{ color: "#EF4A0E" }}
              />
            </a>
            <a
              href="https://walink.co/f717a6"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-600 hover:text-green-800 transform hover:scale-110"
            >
              <FontAwesomeIcon icon={faWhatsapp} size={iconSize} />
            </a>
          </div>
        </div>
      </div>
      <p className="text-center mt-4 text-sm sm:text-base">
        &copy; {new Date().getFullYear()} Tortone Automotores. Powered by{" "}
        <a
          href="http://www.gcarreiras.com.ar"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-blue-800"
        >
          gcarreiras
        </a>
        .
      </p>
    </footer>
  );
};

export default Footer;
