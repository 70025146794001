import React , {useEffect} from "react";
import NavigationButtons from "../NavigationButtons/NavigationButtons";
import WhatsApp from "../WhatsApp/WhatsApp";

const VentaDirecta = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="p-6 flex flex-col justify-center items-center">
    <div className="w-4/5 sm:w-3/5 min-h-[500px] text-center">
      <h2 className="text-2xl font-extrabold mt-6 mb-6">Compra Directa:</h2>
      <ol className="list-decimal list-inside space-y-2 text-lg font-semibold mt-6">
        <li>Comunícate con nosotros para agendar una cita.</li>
        <li>En la agencia, evaluaremos tu vehículo y discutiremos su valor de mercado.</li>
        <li>Te ofreceremos la mejor oferta de compra en efectivo, en la moneda de tu preferencia.</li>
        <li>Si aceptas nuestra oferta, te brindaremos asesoramiento integral para completar la documentación necesaria para la venta.</li>
        <li>Una vez que toda la documentación esté lista, procederemos a la firma del boleto de compra-venta.</li>
        <li>En ese momento, realizaremos el pago por tu vehículo de manera inmediata.</li>
        <li>Es importante destacar que la agencia se hará cargo de todos los gastos asociados a esta transacción.</li>
        <li>Nuestro proceso completo se lleva a cabo en un plazo máximo de 48 horas.</li>
      </ol>
    </div>
  </div>
    <WhatsApp />
    <NavigationButtons/>
  </>
  );
};

export default VentaDirecta;