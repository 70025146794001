import { useState, useEffect } from "react";
import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import "./Carousel.css";

const MyCarousel = ({ items, autoSlide = false, autoSlideInterval = 3000 }) => {
  const [curr, setCurr] = useState(0);

  const prev = () =>
    setCurr((curr) => (curr === 0 ? items.length - 1 : curr - 1));
  const next = () =>
    setCurr((curr) => (curr === items.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, [autoSlide, autoSlideInterval]);

  const renderItem = (item, i) => {
    if (typeof item === "string") {
      return (
        <img src={item} className="carousel-image" alt={`carousel ${i}`} />
      );
    }
    return item;
  };

  return (
    <div className="overflow-hidden relative w-full h-full">
      <div
        className="flex transition-transform ease-out duration-500"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {items.map((item, i) => (
          <div className="w-full flex-shrink-0" key={i}>
            {renderItem(item, i)}
          </div>
        ))}
      </div>
      <div className="absolute inset-0 flex items-center justify-between p-4">
        <button
          onClick={prev}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          <ChevronLeft size={40} />
        </button>
        <button
          onClick={next}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          <ChevronRight size={40} />
        </button>
      </div>
    </div>
  );
};

export default MyCarousel;
