import React from "react";

const CardIndividual = ({ image, name, onInfoClick }) => {
  return (
    <div className="relative overflow-hidden border border-gray-300 
    rounded-lg transform transition-transform duration-200 hover:scale-110 h-64 w-64">
    <div className="h-full w-full flex items-center justify-center">
      <img src={image} alt="" className="h-full w-full object-cover" />
    </div>
    <button
      onClick={onInfoClick}
      className="absolute bottom-2 left-1/2 transform -translate-x-1/2 px-4 py-2 
        bg-blue-500 text-white rounded-md 
        cursor-pointer transition-colors duration-200 hover:bg-blue-700"
    >
      +Info
    </button>
  </div>
  );
};

export default CardIndividual;
