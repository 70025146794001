import React , {useEffect} from "react";
import NavigationButtons from "../NavigationButtons/NavigationButtons";
import WhatsApp from "../WhatsApp/WhatsApp";

const Consignacion = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
     <div className="p-6 flex flex-col justify-center items-center">
    <div className="w-4/5 sm:w-3/5 min-h-[500px] text-center text-justify">
      <h2 className="text-2xl font-extrabold text-center mt-6 mb-6">Consignación:</h2>
      <ul className="list-disc list-inside space-y-2 text-lg font-semibold mt-6">
        <li>
          Nuestra modalidad de consignación te brinda la oportunidad de dejar tu vehículo 
          en nuestra agencia, lo que aumenta significativamente las posibilidades de venta.
        </li>
        <li>
          En nuestra agencia, trabajaremos con tu vehículo como si fuera propio. 
          Ofrecemos a los clientes opciones de financiamiento y, en caso de permuta, 
          la agencia asumirá la responsabilidad de la misma, garantizando que recibas 
          el pago en efectivo.
        </li>
        <li>
          Nos encargamos de todo el proceso de venta, desde la gestión de turnos 
          y documentación previa necesaria, hasta la coordinación con una gestoría 
          profesional para garantizar la correcta transferencia del vehículo.
        </li>
        <li>
          Con nuestra experiencia y dedicación, aseguramos un proceso transparente 
          y eficiente para la venta de tu vehículo bajo la modalidad de consignación.
        </li>
      </ul>
    </div>
  </div>
  <WhatsApp />
    <NavigationButtons/>
  </>
  );
};

export default Consignacion;
